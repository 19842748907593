import { UilMessage } from "@iconscout/react-unicons";

export const NewProject = () => {
  return (
    <section className="project section">
      <div className="project__bg">
        <div className="project__container container grid">
          <div className="project__data">
            <h2 className="project__title">You have a new project?</h2>
            <p className="project__description">
              Lets connect over a cup of coffee.
            </p>
            <a href="#contact" className="button button--flex button--white">
              <span>Contact Me</span>
              <UilMessage size="20" className="project__icon button__icon" />
            </a>
          </div>
          <img
            src="assets/img/connect-coffe.png"
            alt=""
            className="project__img"
          />
        </div>
      </div>
    </section>
  );
};
