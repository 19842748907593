import { UilLinkedinAlt } from "@iconscout/react-unicons";
import { UilInstagram } from "@iconscout/react-unicons";
import { UilGithubAlt } from "@iconscout/react-unicons";
import { ReactComponent as Blob } from "../assets/img/blob.svg";
import { UilScenery } from "@iconscout/react-unicons";
import { UilMouseAlt } from "@iconscout/react-unicons";
import { UilArrowDown } from "@iconscout/react-unicons";
import { forwardRef } from "react";

export const Banner = forwardRef((props, ref) => {
  return (
    <section className="home section" id="home" ref={ref}>
      <div className="home__container container grid">
        <div className="home__content grid">
          <div className="home__social">
            <a
              href="https://www.linkedin.com/in/shubhambhagat98/"
              className="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <UilLinkedinAlt alt="LinkedIn" />
            </a>
            <a
              href="https://github.com/shubhambhagat98/"
              className="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <UilGithubAlt alt="GitHub" />
            </a>
            <a
              href="https://www.instagram.com/shubham_0898/"
              className="home__social-icon"
              target="_blank"
              rel="noreferrer"
            >
              <UilInstagram alt="Instagram" />
            </a>
          </div>

          <div className="home__img">
            <Blob />
          </div>

          <div className="home__data">
            <h1 className="home__title">Hi, I am Shubham</h1>
            <h3 className="home__subtitle">Software Engineer</h3>
            <p className="home__description">
              Experience in frontend web design and backend development,
              producing quality work.
            </p>
            <a href="#projects" className="button button--flex">
              <span>See my work</span>
              <UilScenery size="18" className="button__icon" />
            </a>
          </div>
        </div>

        <div className="home__scroll">
          <a href="#about" className="home__scroll-button button--flex">
            <UilMouseAlt className="home__scroll-mouse" size="32" />
            <span className="home__scroll-name">Scroll down</span>
            <UilArrowDown className="home__scroll-arrow" size="20" />
          </a>
        </div>
      </div>
    </section>
  );
});
