import { MainNavigation } from "./sections/MainNavigation";
import { Banner } from "./sections/Banner";
import { About } from "./sections/About";
import { Skills } from "./sections/Skills";
import { Qualification } from "./sections/Qualification";
import { Projects } from "./sections/Projects";
import { NewProject } from "./sections/NewProject";
import { Contact } from "./sections/Contact";
import { Footer } from "./sections/Footer";
import { ScrollToTop } from "./sections/ScrollToTop";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";
import { Interests } from "./sections/Interests";

function App() {
  const [homeRef, homeInView] = useInView({
    threshold: 0.5,
  });

  const [aboutRef, aboutInView] = useInView({
    threshold: 0.5,
  });
  const [skillRef, skillInView] = useInView({
    threshold: 0.5,
  });
  const [qualRef, qualInView] = useInView({
    threshold: 0.5,
  });
  const [projectsRef, projectsInView] = useInView({
    threshold: 0.5,
  });
  const [contactRef, contactInView] = useInView({
    threshold: 0.5,
  });

  const activeTab = () => {
    if (homeInView) {
      return 0;
    } else if (aboutInView) {
      return 1;
    } else if (projectsInView) {
      return 2;
    } else if (skillInView) {
      return 3;
    } else if (qualInView) {
      return 4;
    } else if (contactInView) {
      return 5;
    } else {
      return -1;
    }
  };

  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    keepTheme();
  }, []);

  const keepTheme = () => {
    if (localStorage.getItem("portfolio-theme")) {
      if (localStorage.getItem("portfolio-theme") === "theme-dark") {
        setIsDarkTheme(true);
      } else if (localStorage.getItem("portfolio-theme") === "theme-light") {
        setIsDarkTheme(false);
      }
    } else {
      setThemeHandler("theme-light");
    }
  };

  const setThemeHandler = (themeName) => {
    localStorage.setItem("portfolio-theme", themeName);
    setIsDarkTheme(themeName === "theme-dark" ? true : false);
  };

  const toggleTheme = () => {
    if (localStorage.getItem("portfolio-theme") === "theme-dark") {
      setThemeHandler("theme-light");
    } else {
      setThemeHandler("theme-dark");
    }
  };

  return (
    <div className={"body-div " + (isDarkTheme && "dark-theme")}>
      <MainNavigation activeTab={activeTab} toggleTheme={toggleTheme} />
      <main className="main">
        <Banner ref={homeRef} />
        <About ref={aboutRef} />
        <Projects ref={projectsRef} />
        <Skills ref={skillRef} isDarkTheme={isDarkTheme} />
        <Qualification ref={qualRef} />
        <NewProject />
        <Interests />
        <Contact ref={contactRef} />
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
