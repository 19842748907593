import { UilGraduationCap } from "@iconscout/react-unicons";
import { UilBriefcaseAlt } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilAngleDown } from "@iconscout/react-unicons";
import { UilAngleUp } from "@iconscout/react-unicons";
import { useState, forwardRef, useRef } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import MyWork from "../data/work.json";
import MyEduction from "../data/education.json";

export const Qualification = forwardRef((props, ref) => {
  const [showWork, setShowWork] = useState(true);
  const [expandedWorkItem, setExpandedWorkItem] = useState(-1);
  const workRefs = useRef([]);
  //you can access the elements with workRefs.current[i]
  const [expandedEducItem, setExpandedEducItem] = useState(-1);
  const educRefs = useRef([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [showWork]);

  const showWorkHandler = () => {
    setShowWork((prev) => !prev);
  };

  const expandWorkItem = (idx) => {
    // console.log(workRefs.current[idx].clientHeight);
    if (idx === expandedWorkItem) {
      workRefs.current[idx].style.maxHeight = null;
      workRefs.current[idx].style.marginBottom = 0;
      setExpandedWorkItem(-1);
    } else {
      if (expandedWorkItem !== -1) {
        //collapse previously expanded item
        workRefs.current[expandedWorkItem].style.maxHeight = null;
        workRefs.current[expandedWorkItem].style.marginBottom = 0;
      }

      workRefs.current[idx].style.maxHeight =
        workRefs.current[idx].scrollHeight + "px";
      workRefs.current[idx].style.marginBottom = "var(--mb-1)";
      setExpandedWorkItem(idx);
    }
  };

  const expandEducItem = (idx) => {
    if (idx === expandedEducItem) {
      educRefs.current[idx].style.maxHeight = null;
      educRefs.current[idx].style.marginBottom = 0;
      setExpandedEducItem(-1);
    } else {
      if (expandedEducItem !== -1) {
        //collapse previously expanded item
        educRefs.current[expandedEducItem].style.maxHeight = null;
        educRefs.current[expandedEducItem].style.marginBottom = 0;
      }

      educRefs.current[idx].style.maxHeight =
        educRefs.current[idx].scrollHeight + "px";
      educRefs.current[idx].style.marginBottom = "var(--mb-1)";
      setExpandedEducItem(idx);
    }
  };

  return (
    <section className="qualification section" id="qualification" ref={ref}>
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personal journey</span>

      <div className="qualifiction__container container">
        <div className="qualification__tabs">
          <div
            // className="qualification__button button--flex"
            className={
              showWork
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={showWorkHandler}
          >
            <UilBriefcaseAlt size="24" className="qualification__icon" />
            <span>Work</span>
          </div>
          <div
            // className="qualification__button button--flex"
            className={
              !showWork
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={showWorkHandler}
          >
            <UilGraduationCap size="24" className="qualification__icon" />
            <span>Education</span>
          </div>
        </div>

        <div className="qualification__sections">
          {/* education */}
          <div
            className={
              !showWork
                ? "qualification__content qualification__active"
                : "qualification__content"
            }
            data-content
            id="education"
          >
            {/* masters */}
            {MyEduction.slice(0)
              .reverse()
              .map((educ, idx) =>
                idx % 2 === 0 ? (
                  <div className="qual__data" key={idx}>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="600"
                      className="qualification__card"
                      onClick={() => expandEducItem(idx)}
                    >
                      <div className="qual__title__wrapper">
                        <h3 className="qualification__title">{educ.major}</h3>

                        {idx === expandedWorkItem ? (
                          <UilAngleUp className="qual-card-icon" />
                        ) : (
                          <UilAngleDown className="qual-card-icon" />
                        )}
                      </div>

                      <span className="qualification__subtitle">
                        {educ.degree}
                      </span>
                      <div
                        className="qual_bullet-list"
                        ref={(el) => (educRefs.current[idx] = el)}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                          }}
                        >
                          {educ.description.map((item, idx) => (
                            <li key={idx + 1 + "list-item1"}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="qualification__calender">
                        <UilCalendarAlt
                          size="13"
                          className="qualification__calender-icon"
                        />
                        <span>{educ.duration}</span>
                      </div>
                    </div>
                    <div className="qual__marker--col">
                      <span className="qualification__rounder"></span>
                      <span className="qualification__line"></span>
                    </div>
                  </div>
                ) : (
                  <div className="qual__data" key={idx}>
                    <div className="empty-div"></div>
                    <div className="qual__marker--col">
                      <span className="qualification__rounder"></span>
                      <span className="qualification__line"></span>
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="600"
                      data-aos-delay="100"
                      className="qualification__card"
                      onClick={() => expandEducItem(idx)}
                    >
                      <div className="qual__title__wrapper">
                        <h3 className="qualification__title">{educ.major}</h3>

                        {idx === expandedWorkItem ? (
                          <UilAngleUp className="qual-card-icon" />
                        ) : (
                          <UilAngleDown className="qual-card-icon" />
                        )}
                      </div>
                      <span className="qualification__subtitle">
                        {educ.degree}
                      </span>
                      <div
                        className="qual_bullet-list"
                        ref={(el) => (educRefs.current[idx] = el)}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                          }}
                        >
                          {educ.description.map((item, idx) => (
                            <li key={idx + "item1"}>{item}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="qualification__calender">
                        <UilCalendarAlt
                          size="13"
                          className="qualification__calender-icon"
                        />
                        <span>{educ.duration}</span>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>

          {/* work */}
          <div
            className={
              showWork
                ? "qualification__content qualification__active"
                : "qualification__content"
            }
            data-content
            id="work"
          >
            {MyWork.slice(0)
              .reverse()
              .map((work, idx) =>
                idx % 2 === 0 ? (
                  <div className="qual__data" key={idx}>
                    <div
                      data-aos="fade-right"
                      data-aos-duration="600"
                      className="qualification__card"
                      onClick={() => expandWorkItem(idx)}
                    >
                      <div className="qual__title__wrapper">
                        <h3 className="qualification__title">
                          {work.position}
                        </h3>

                        {idx === expandedWorkItem ? (
                          <UilAngleUp className="qual-card-icon" />
                        ) : (
                          <UilAngleDown className="qual-card-icon" />
                        )}
                      </div>

                      <span className="qualification__subtitle">
                        {work.company}
                      </span>
                      <div
                        className="qual_bullet-list"
                        ref={(el) => (workRefs.current[idx] = el)}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                          }}
                        >
                          {work.description?.map((item, idx) => (
                            <li key={idx + 1 + "list-item"}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="qualification__calender">
                        <UilCalendarAlt
                          size="13"
                          className="qualification__calender-icon"
                        />
                        <span>{work.duration}</span>
                      </div>
                    </div>
                    <div className="qual__marker--col">
                      <span className="qualification__rounder"></span>
                      <span className="qualification__line"></span>
                    </div>
                  </div>
                ) : (
                  <div className="qual__data" key={idx}>
                    <div className="empty-div"></div>
                    <div className="qual__marker--col">
                      <span className="qualification__rounder"></span>
                      <span className="qualification__line"></span>
                    </div>
                    <div
                      data-aos="fade-left"
                      data-aos-duration="600"
                      data-aos-delay="100"
                      className="qualification__card"
                      onClick={() => expandWorkItem(idx)}
                    >
                      <div className="qual__title__wrapper">
                        <h3 className="qualification__title">
                          {work.position}
                        </h3>

                        {idx === expandedWorkItem ? (
                          <UilAngleUp className="qual-card-icon" />
                        ) : (
                          <UilAngleDown className="qual-card-icon" />
                        )}
                      </div>
                      <span className="qualification__subtitle">
                        {work.company}
                      </span>
                      <div
                        className="qual_bullet-list"
                        ref={(el) => (workRefs.current[idx] = el)}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                          }}
                        >
                          {work.description.map((item, idx) => (
                            <li key={idx + "item"}>{item}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="qualification__calender">
                        <UilCalendarAlt
                          size="13"
                          className="qualification__calender-icon"
                        />
                        <span>{work.duration}</span>
                      </div>
                    </div>
                  </div>
                )
              )}

            {/* end work */}
          </div>
        </div>
      </div>
    </section>
  );
});
