import { UilLinkedinAlt } from "@iconscout/react-unicons";
import { UilInstagram } from "@iconscout/react-unicons";
import { UilGithubAlt } from "@iconscout/react-unicons";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h3 className="footer__title">Shubham Bhagat</h3>
            <span className="footer__subtitle">Software Engineer</span>
          </div>

          <ul className="footer__links">
            <li>
              <a href="#about" className="footer__link">
                About Me
              </a>
            </li>
            <li>
              <a href="#projects" className="footer__link">
                Projects
              </a>
            </li>
            <li>
              <a href="#contact" className="footer__link">
                Contact Me
              </a>
            </li>
          </ul>
          <div className="footer__socials">
            <a
              href="https://www.linkedin.com/in/shubhambhagat98/"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilLinkedinAlt size="20" />
            </a>
            <a
              href="https://github.com/shubhambhagat98?tab=repositories"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilGithubAlt size="20" />
            </a>
            <a
              href="https://www.instagram.com/shubham_0898/"
              target="_blank"
              rel="noreferrer"
              className="footer__social"
            >
              <UilInstagram size="20" />
            </a>
          </div>
        </div>

        <div className="footer__credits">
          <p>
            Made with <span className="footer__heart">&hearts;</span> using
            React JS
          </p>
          <p>
            Icons from iconscout.com and devicon.dev & Mockup images from
            mockuper.net
          </p>
        </div>

        <p className="footer__copy">
          {" "}
          &#169; Shubham Bhagat. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};
