import { UilArrowRight } from "@iconscout/react-unicons";
import { UilAirplay } from "@iconscout/react-unicons";
import { UilGithubAlt } from "@iconscout/react-unicons";
// import { UilBrowser } from '@iconscout/react-unicons';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";

import MyProjects from "../data/projects.json";
import { useState, forwardRef } from "react";
import { ProjectModal } from "../common/ProjectModal";

export const Projects = forwardRef((props, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const [projectId, setProjectId] = useState(0);

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const openModalHandler = (id) => {
    setProjectId(id);
    setOpenModal(true);
  };

  return (
    <>
      <section className="projects section" id="projects" ref={ref}>
        <h2 className="section__title">Projects</h2>
        <span className="section__subtitle">Most recent work</span>

        <div className="projects__container container">
          <Swiper
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {MyProjects.slice(0)
              .reverse()
              .map((project) => (
                <SwiperSlide
                  className="projects__content projects__swiper grid"
                  key={project.id}
                >
                  <img
                    src={project.image_src}
                    alt=""
                    className="projects__img"
                    onClick={() => openModalHandler(project.id)}
                  />

                  <div className="projects__data">
                    <h3 className="projects__title">{project.title}</h3>
                    <p className="projects__description">
                      {project.projects__description}
                    </p>
                    <div className="projects__button--div">
                      <span
                        className="button button--flex  modal__github--btn"
                        onClick={() => openModalHandler(project.id)}
                      >
                        <span>Demo</span>
                        <UilArrowRight size="20" className="button__icon" />
                      </span>

                      {project.live__link !== "" ? (
                        <a
                          href={project.live__link}
                          className="button button--flex modal__github--btn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span style={{ marginRight: ".1rem" }}>Live</span>
                          <UilAirplay size="16" className="button__icon" />
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={project.github__link}
                          className="button button--flex modal__github--btn"
                          rel="noreferrer"
                        >
                          <span>GitHub</span>
                          <UilGithubAlt size="15" className="button__icon" />
                        </a>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className="projects__content-grid grid">
            {MyProjects.slice(0)
              .reverse()
              .map((project) => (
                <div
                  className="projects__content  grid"
                  key={"proj" + project.id}
                >
                  <img
                    src={project.image_src}
                    alt=""
                    className="projects__img"
                    onClick={() => openModalHandler(project.id)}
                  />
                  <div className="projects__data">
                    <h3 className="projects__title">{project.title}</h3>
                    <p className="projects__description">
                      {project.projects__description}
                    </p>
                    <div className="projects__button--div">
                      <span
                        className="button button--flex  modal__github--btn"
                        onClick={() => openModalHandler(project.id)}
                      >
                        <span>Demo</span>
                        <UilArrowRight size="20" className="button__icon" />
                      </span>

                      {project.live__link !== "" ? (
                        <a
                          href={project.live__link}
                          className="button button--flex modal__github--btn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span style={{ marginRight: ".1rem" }}>Live</span>
                          <UilAirplay size="16" className="button__icon" />
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={project.github__link}
                          className="button button--flex modal__github--btn"
                          rel="noreferrer"
                        >
                          <span>GitHub</span>
                          <UilGithubAlt size="15" className="button__icon" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {openModal && (
          <ProjectModal
            isOpen={true}
            closeModalHandler={closeModalHandler}
            projectId={projectId}
          />
        )}
      </section>
    </>
  );
});
