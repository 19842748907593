import { Skill } from "../common/Skill";
import { UilBracketsCurly } from "@iconscout/react-unicons";
import { UilServerNetwork } from "@iconscout/react-unicons";
import { UilHorizontalDistributionCenter } from "@iconscout/react-unicons";
import { UilDatabase } from "@iconscout/react-unicons";
import { useEffect, forwardRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Skills = forwardRef((props, ref) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="skills section" id="skills" ref={ref}>
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My techincal expertise</span>
      <div className="skills__container container grid">
        <div>
          {/* ===============skills 1================= */}
          <div className="skills__content">
            <div className="skills__header">
              <UilBracketsCurly className="skill_icon" size="22" />
              <div>
                <h1 className="skills__title">Frontend development</h1>
              </div>
            </div>
            <div className="skills__grid">
              <div data-aos="zoom-in" data-aos-duration="400">
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain-wordmark.svg"
                  alt="The logo icon for HTML 5"
                  title="HTML 5"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="200"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain-wordmark.svg"
                  alt="The logo icon for CSS3"
                  title="CSS 3"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="400"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
                  alt="The logo icon for JavaScript"
                  title="JavaScript"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="600"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
                  alt="The logo icon for react"
                  title="React"
                />
              </div>
            </div>
          </div>
          {/* ===============skills 2================= */}
          <div className="skills__content">
            <div className="skills__header">
              <UilServerNetwork className="skill_icon" size="22" />
              <div>
                <h1 className="skills__title">Backend development</h1>
              </div>
            </div>
            <div className="skills__grid">
              <div data-aos="zoom-in" data-aos-duration="400">
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg"
                  alt="The logo icon for Java"
                  title="Java"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="200"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original-wordmark.svg"
                  alt="The logo icon for Python"
                  title="Python"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="400"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg"
                  alt="The logo icon for PHP"
                  title="PHP"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="600"
              >
                <Skill
                  source={
                    props.isDarkTheme
                      ? "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-plain-wordmark.svg"
                      : "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original-wordmark.svg"
                  }
                  alt="The logo icon for AWS"
                  title="Amazon Web Services"
                />
              </div>
            </div>
          </div>
          {/* ===============skills 3================= */}
          <div className="skills__content">
            <div className="skills__header">
              <UilHorizontalDistributionCenter
                className="skill_icon"
                size="22"
              />
              <div>
                <h1 className="skills__title">Frameworks</h1>
              </div>
            </div>
            <div className="skills__grid">
              <div data-aos="zoom-in" data-aos-duration="400">
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original-wordmark.svg"
                  alt="The logo icon for Spring"
                  title="Spring"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="200"
              >
                <Skill
                  source={
                    props.isDarkTheme
                      ? "/assets/img/nextjs__logo__dark.svg"
                      : "/assets/img/nextjs__logo.svg"
                  }
                  alt="The logo icon for Next.js"
                  title="Next.js"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="400"
              >
                <Skill
                  source="assets/img/flask__logo.svg"
                  alt="The logo icon for Flask"
                  title="Flask"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="600"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg"
                  alt="The logo icon for Bootstrap"
                  title="Bootstrap"
                />
              </div>
            </div>
          </div>
          {/* ===============skills 4================= */}
          <div className="skills__content">
            <div className="skills__header">
              <UilDatabase className="skill_icon" size="22" />
              <div>
                <h1 className="skills__title">Database</h1>
              </div>
            </div>
            <div className="skills__grid">
              <div data-aos="zoom-in" data-aos-duration="400">
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg"
                  alt="The logo icon for mysql"
                  title="MySQL"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="200"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original-wordmark.svg"
                  alt="The logo icon for postgresql"
                  title="PostgreSQL"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="400"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg"
                  alt="The logo icon for Mongodb"
                  title="MongoDB"
                />
              </div>

              <div
                data-aos="zoom-in"
                data-aos-duration="400"
                data-aos-delay="600"
              >
                <Skill
                  source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original-wordmark.svg"
                  alt="The logo icon for Redis"
                  title="Redis"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
