import { Tooltip } from "@mui/material";

export const Skill = ({ source, alt, title }) => {
  return (
    <Tooltip title={title} arrow>
      <div className="skill__image__wrap">
        <img src={source} alt={alt} />
      </div>
    </Tooltip>
  );
};
