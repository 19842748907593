import { UilDownloadAlt } from "@iconscout/react-unicons";
import { forwardRef } from "react";

export const About = forwardRef((props, ref) => {
  return (
    <section className="about section" id="about" ref={ref}>
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My introduction</span>

      <div className="about__container container grid">
        <img
          src="assets/img/shubham-about.webp"
          alt=""
          className="about__img about__img--short"
        />

        <img
          src="assets/img/shubham-about-2.webp"
          alt=""
          className="about__img about__img--long"
        />

        <div className="about__data">
          <div>
            <p className="about__description">
              I am a recent computer science graduate from Indiana University
              Bloomington, equipped with a strong foundation of technical
              expertise and practical experience. In my previous role at
              Accenture, I honed my skills in agile software development and
              REST API integration. Proficient in both frontend and backend
              development, I am eager to leverage my abilities in a full-time
              software engineering role.
            </p>

            <p className="about__description">
              My technical abilities include programming languages such as Java,
              Python, and JavaScript, as well as frameworks like React.js and
              Spring Boot. I am well-versed in database technologies like MySQL
              and MongoDB and experienced in version control tools like Git and
              GitHub.
            </p>

            <p className="about__description">
              But my passions extend beyond just code. In my free time, I enjoy
              playing guitar, sketching portraits, and cooking. I have even
              pursued my love of music as a freelance producer, collaborating
              with other musicians on various projects.
            </p>
          </div>
          <div className="about__buttons">
            <a
              target="_blank"
              href="assets/pdf/shubham_bhagat_resume.pdf"
              className="button button--flex"
            >
              <span style={{ marginRight: ".1rem" }}>Resume</span>
              <UilDownloadAlt size="22" className="button__icon" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});
