import { UilArrowUp } from "@iconscout/react-unicons";
import { useEffect, useState } from "react";

export const ScrollToTop = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", showScrollToTopHandler);

    //cleanup function
    return () => {
      window.removeEventListener("scroll", showScrollToTopHandler);
    };
  }, [showScrollToTop]);

  const showScrollToTopHandler = () => {
    if (window.scrollY >= 560) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  return (
    <a
      href="#home"
      className={showScrollToTop ? "scrollup show-scroll" : "scrollup"}
      id="scrollup"
    >
      <UilArrowUp className="scrollup__icon" />
    </a>
  );
};
