import { UilPhone } from "@iconscout/react-unicons";
import { UilEnvelope } from "@iconscout/react-unicons";

import { UilMessage, UilLinkedinAlt } from "@iconscout/react-unicons";
import { forwardRef, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export const Contact = forwardRef((props, ref) => {
  const formRef = useRef();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_KEY).then(
      (result) => {
        setOpen(true);
        formRef.current.reset();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <section className="contact section" id="contact" ref={ref}>
      <h2 className="section__title">Contact Me</h2>
      <span className="section__subtitle">Get in touch</span>

      <div className="contact__container container grid">
        <div>
          <div className="contact__information">
            <UilPhone className="contact__icon" size="30" />
            <div>
              <h3 className="contact__title">Call Me</h3>
              <span className="contact__subtitle">+1 812-671-7382</span>
            </div>
          </div>

          <div className="contact__information">
            <UilEnvelope className="contact__icon" size="30" />
            <div>
              <h3 className="contact__title">Email</h3>
              <span className="contact__subtitle">
                shubhambhagat98@gmail.com
              </span>
            </div>
          </div>

          <div className="contact__information">
            <UilLinkedinAlt className="contact__icon" size="30" />
            <div>
              <h3 className="contact__title">LinkedIn</h3>
              <span className="contact__subtitle">
                linkedin.com/in/shubhambhagat98
              </span>
            </div>
          </div>
        </div>

        <form
          className="contact__form grid"
          ref={formRef}
          onSubmit={handleSubmit}
          action=""
        >
          <div className="contact__inputs grid">
            <div className="contact__content">
              <label htmlFor="name__input" className="contact__label">
                Name
              </label>
              <input
                type="text"
                className="contact__input"
                id="name__input"
                name="user_name"
                autoComplete="off"
                required
              />
            </div>
            <div className="contact__content">
              <label htmlFor="email__input" className="contact__label">
                Email
              </label>
              <input
                type="email"
                className="contact__input"
                id="email__input"
                name="user_email"
                autoComplete="off"
                required
              />
            </div>
          </div>
          <div className="contact__content">
            <label htmlFor="subject__input" className="contact__label">
              Subject
            </label>
            <input
              type="text"
              className="contact__input"
              id="subject__input"
              name="user_subject"
              autoComplete="off"
              required
            />
          </div>
          <div className="contact__content">
            <label htmlFor="message__input" className="contact__label">
              Message
            </label>
            <textarea
              className="contact__input"
              name="user_message"
              id="message__input"
              cols="0"
              rows="7"
              autoComplete="off"
              required
            />
          </div>

          <div>
            <button
              type="submit"
              className="contact__button button button--flex"
            >
              <span>Send Message</span>
              <UilMessage size="20" className="button__icon" />
            </button>
          </div>
        </form>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Message sent successfully !"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key="success_snackbar"
      />
    </section>
  );
});
