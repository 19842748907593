import { Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import Projects from "../data/projects.json";
import { UilTimes } from "@iconscout/react-unicons";
import ReactPlayer from "react-player/youtube";
import { Skill } from "./Skill";
import { UilGithubAlt } from "@iconscout/react-unicons";
import { UilAirplay } from "@iconscout/react-unicons";

export const ProjectModal = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  const id = props.projectId;

  useEffect(() => {
    handleClickOpen();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.closeModalHandler();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      className="project__modal--dialog"
      disablePortal
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title" sx={{ px: { xs: 2, sm: 3 } }}>
        <div className="project__modal--title">
          <div>{Projects[id].title}</div>
          <UilTimes
            size="28"
            onClick={handleClose}
            className="modal__close--icon"
          />
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          px: { xs: 2, sm: 3 },
        }}
      >
        <div className="modal__video--player">
          <ReactPlayer
            url={Projects[id].video__link}
            controls
            width="100%"
            height="100%"
          />
        </div>
        <DialogContentText className="modal__project--summary">
          Summary
        </DialogContentText>
        <DialogContentText className="modal__project--summary--text">
          {Projects[id].summary}
        </DialogContentText>

        <div className="modal__button-wrap">
          <a
            target="_blank"
            href={Projects[id].github__link}
            className="button button--flex modal__github--btn"
            rel="noreferrer"
          >
            <span>GitHub</span>
            <UilGithubAlt size="15" className="button__icon" />
          </a>

          {Projects[id].live__link !== "" && (
            <a
              target="_blank"
              href={Projects[id].live__link}
              className="button button--flex modal__github--btn"
              rel="noreferrer"
            >
              <span>Live</span>
              <UilAirplay size="15" className="button__icon" />
            </a>
          )}
        </div>

        <DialogContentText className="modal__project--summary">
          Tech Stack
        </DialogContentText>

        <div className="modal__tech--stack">
          {Projects[id].tech__stack.map((tech, idx) => (
            <div key={idx + 100}>
              <Skill
                key={idx}
                source={tech.link}
                alt={`logo for ${tech.skill}`}
                title={tech.skill}
              />
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
