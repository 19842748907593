import { Swiper, SwiperSlide } from "swiper/react";
import MyInterests from "../data/interests.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";

export const Interests = () => {
  return (
    <>
      <section className="interests section" id="interests">
        <h2 className="section__title">Interests</h2>
        <span className="section__subtitle">
          Extracurricular Activities & Hobbies
        </span>

        <div className="interests__container container">
          <Swiper
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {MyInterests.map((interest) => (
              <SwiperSlide
                key={interest.id}
                className="interests__content interests__swiper grid"
              >
                <img
                  src={interest.image_src}
                  alt=""
                  className="interests__img"
                />

                <div className="interests__data">
                  <h3 className="interests__title">{interest.title}</h3>
                  <p className="interests__description">
                    {interest.description}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="interests__content-grid grid">
            {MyInterests.map((interest) => (
              <div key={interest.id + "id"} className="interests__content grid">
                <img
                  src={interest.image_src}
                  alt=""
                  className="interests__img"
                />

                <div className="interests__data">
                  <h3 className="interests__title">{interest.title}</h3>
                  <p className="interests__description">
                    {interest.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
