import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// eslint-disable-next-line
import "swiper/css/bundle";
import App from "./App";
// import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
