import { UilEstate } from "@iconscout/react-unicons";
import { useEffect, useRef, useState } from "react";
import { UilUser } from "@iconscout/react-unicons";
import { UilFileAlt } from "@iconscout/react-unicons";
import { UilBriefcaseAlt } from "@iconscout/react-unicons";
import { UilScenery } from "@iconscout/react-unicons";
import { UilMessage } from "@iconscout/react-unicons";
import { UilTimes } from "@iconscout/react-unicons";
import { UilApps } from "@iconscout/react-unicons";
import { UilMoon } from "@iconscout/react-unicons";
import { UilSun } from "@iconscout/react-unicons";
// import { HashLink } from "react-router-hash-link";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Link,
} from "@mui/material";

const sections = [
  {
    Title: "Home",
    Link: "#home",
    Icon: (
      <UilEstate size="18" style={{ margin: "5px" }} className="nav__icon" />
    ),
  },
  {
    Title: "About",
    Link: "#about",
    Icon: <UilUser size="18" style={{ margin: "5px" }} className="nav__icon" />,
  },
  {
    Title: "Projects",
    Link: "#projects",
    Icon: (
      <UilScenery size="18" style={{ margin: "5px" }} className="nav__icon" />
    ),
  },
  {
    Title: "Skills",
    Link: "#skills",
    Icon: (
      <UilFileAlt size="18" style={{ margin: "5px" }} className="nav__icon" />
    ),
  },
  {
    Title: "Qualification",
    Link: "#qualification",
    Icon: (
      <UilBriefcaseAlt
        size="18"
        style={{ margin: "5px" }}
        className="nav__icon"
      />
    ),
  },

  {
    Title: "Contact Me",
    Link: "#contact",
    Icon: (
      <UilMessage size="18" style={{ margin: "5px" }} className="nav__icon" />
    ),
  },
];

const drawerWidth = 270;

export const MainNavigation = ({ activeTab, toggleTheme }) => {
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [scrollHeader, setScrollHeader] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const headerRef = useRef();

  useEffect(() => {
    window.addEventListener("scroll", scrollHeaderHandler);

    //cleanup function
    return () => {
      window.removeEventListener("scroll", scrollHeaderHandler);
    };
  }, [scrollHeader]);

  useEffect(() => {
    localStorage.getItem("portfolio-theme") === "theme-dark"
      ? setIsDarkTheme(true)
      : setIsDarkTheme(false);
  }, []);

  const showBottomNavHandler = () => {
    // console.log(showBottomNav);
    setShowBottomNav(true);
  };

  const closeBottomNavHandler = () => {
    setShowBottomNav(false);
  };

  const scrollHeaderHandler = () => {
    if (window.scrollY >= 60) {
      setScrollHeader(true);
    } else {
      setScrollHeader(false);
    }
  };

  const toggleThemeHandler = () => {
    setIsDarkTheme((prev) => !prev);
    toggleTheme();
  };

  const drawer = (
    <Box onClick={closeBottomNavHandler} sx={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ my: 2 }}>
        Shubham
      </Typography>
      <Divider className="drawer--divider " />
      <List>
        {sections.map((section, idx) => (
          <ListItem key={idx}>
            <ListItemButton
              selected={activeTab() === idx}
              component={Link}
              href={section.Link}
              // className="nav__link"
            >
              <ListItemIcon
                className={activeTab() === idx ? "active-drawer-link" : ""}
                sx={{ minWidth: 0, mr: 2, color: "inherit" }}
              >
                {section.Icon}
              </ListItemIcon>
              <ListItemText
                primary={section.Title}
                className={activeTab() === idx ? "active-drawer-link" : ""}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => document.body : undefined;

  return (
    <header
      className={scrollHeader ? "header scroll-header" : "header"}
      id="header"
      ref={headerRef}
    >
      {/* {console.log(scrollHeader)} */}
      <nav className="nav container">
        <a href="#home" className="nav__logo">
          Shubham
        </a>
        <div className={"nav__menu"} id="nav-menu">
          <ul className="grid nav__list ">
            {sections.map((section, idx) => (
              <li className="nav__item" key={idx}>
                <a
                  id={`nav__link__${section.Link}`}
                  href={section.Link}
                  className={
                    activeTab() === idx ? "nav__link active-link" : "nav__link"
                  }
                  onClick={closeBottomNavHandler}
                >
                  {section.Icon}
                  <span>{section.Title}</span>
                </a>
              </li>
            ))}
          </ul>
          <UilTimes
            size="24"
            className="nav__close"
            onClick={closeBottomNavHandler}
          />
        </div>

        <div className="nav__btns">
          {/* Theme change button */}

          {isDarkTheme ? (
            <UilSun
              size="18"
              className="change-theme"
              id="theme-button"
              onClick={toggleThemeHandler}
            />
          ) : (
            <UilMoon
              size="18"
              className="change-theme"
              id="theme-button"
              onClick={toggleThemeHandler}
            />
          )}

          <div
            className="nav__toggle"
            id="nav-toggle"
            onClick={showBottomNavHandler}
          >
            <UilApps size="18" />
          </div>
        </div>
      </nav>
      <Box component="nav">
        <Drawer
          disablePortal
          className="nav__drawer"
          anchor="right"
          container={container}
          variant="temporary"
          open={showBottomNav}
          onClose={closeBottomNavHandler}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundImage: "none !important",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </header>
  );
};
